import Banner1 from "../assets/images/banner-1.png";
import Banner2 from "../assets/images/banner-2.jpg";
import Banner3 from "../assets/images/banner-3.png";
import Banner4 from "../assets/images/banner-4.png";
import Banner5 from "../assets/images/banner-5.jpg";
import Banner6 from "../assets/images/banner-6.jpg";
import Banner7 from "../assets/images/banner-7.jpg";

import Frame1 from "../assets/images/frame-1.png";
import Frame2 from "../assets/images/frame-2.jpg";
import Frame3 from "../assets/images/frame-3.png";
import Frame4 from "../assets/images/frame-4.png";
import Frame5 from "../assets/images/frame-5.jpg";
import Frame6 from "../assets/images/banner-6.jpg";
import Frame7 from "../assets/images/banner-7.jpg";

import Image2 from "../assets/images/image-2.png";
import Image3 from "../assets/images/why-img.png";

import Image4 from "../assets/images/image-4.png";
import Image4a from "../assets/images/image-4a.png";

import Image8 from "../assets/images/banner-info-app.png";
import Image6 from "../assets/images/banner-info-web.png";
import Image5 from "../assets/images/image-5-1.png";
import Image7 from "../assets/images/image-7.png";
import Step1 from "../assets/images/step1.png";
import Step2 from "../assets/images/step2.png";
import Step3 from "../assets/images/step3.png";

import Avt1 from "../assets/images/avt-1.png";
import Avt2 from "../assets/images/avt-2.png";
import Avt3 from "../assets/images/avt-3.png";
import Avt4 from "../assets/images/avt-4.jpeg";
import Avt5 from "../assets/images/avt-5.png";
import Avt6 from "../assets/images/avt-6.jpg";
import Avt7 from "../assets/images/avt-7.jpg";
import Avt8 from "../assets/images/avt-8.jpg";
import Avt9 from "../assets/images/avt-9.jpg";
import Avt10 from "../assets/images/avt-10.jpg";
import AvtGroup from "../assets/images/avt-group.png";

import AhamovePartner from "../assets/images/ahamove.png";
import BVPartner from "../assets/images/bao-viet.png";
import HVPartner from "../assets/images/bh-hvuong.png";
import BhtcPartner from "../assets/images/bh-toan-cau.png";
import EPassPartner from "../assets/images/epass.png";
import GrabPartner from "../assets/images/grab.png";
import LianPartner from "../assets/images/lian.png";
import MicPartner from "../assets/images/mic.png";
import OneLinicPartner from "../assets/images/one-clinic.png";
import PtiPartner from "../assets/images/pti.png";
import PviPartner from "../assets/images/pvi.png";
import ShopbackPartner from "../assets/images/shop-back.png";
import TgddPartner from "../assets/images/tgdd.png";
// import VacPartner from "../assets/images/vac.png";
import VniPartner from "../assets/images/vni.png";

import News1 from "../assets/images/news-1.png";
import News2 from "../assets/images/news-2.png";
import News3 from "../assets/images/news-3.png";
import News4 from "../assets/images/news-4.png";
import News5 from "../assets/images/news-5.jpg";

import BannerNha from "../assets/images/banner-bh-nha.png";
import BannerTaiNan from "../assets/images/banner-bh-tainan.png";
import BannerDulich from "../assets/images/banner-bh-travel.png";
import BannerTrucot from "../assets/images/banner-bh-trucot.jpg";
import BannerVCX from "../assets/images/banner-bh-vcxe.jpg";
import BannerNgheo from "../assets/images/banner-bhngheo.jpg";
import BannerBHYT from "../assets/images/banner-bhyt.jpg";
import BannerBMCare from "../assets/images/banner-bmcare.png";
import BannerMCXe from "../assets/images/banner-mcmc-xm.jpg";
import BannerHTri from "../assets/images/banner-tkhtri.png";
import BannerBBXM from "../assets/images/banner-tnds-bbxm.jpg";
import BannerTomato from "../assets/images/banner-tomato.jpg";
import BannerSKV from "../assets/images/banner-skv.jpg";
import BannerNhaToanDien from "../assets/images/banner-nha-toan-dien.jpg";
import BannerVBICare from "../assets/images/vbi-care.jpg";
import BannerFamilyCare from "../assets/images/family-care.jpg";
import BannerTaiNanHoGiaDinh from "../assets/images/banner-7.jpg";
const Images = {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  Banner6,
  Banner7,
  Frame1,
  Frame2,
  Frame3,
  Frame4,
  Frame5,
  Frame6,
  Frame7,
  Image2,
  Image3,
  Image4,
  Image4a,
  Image5,
  Step1,
  Step2,
  Step3,
  Image6,
  Image7,
  Image8,
  Avt1,
  Avt2,
  Avt3,
  Avt4,
  Avt5,
  Avt6,
  Avt7,
  Avt8,
  Avt9,
  Avt10,
  AvtGroup,
  BhtcPartner,
  LianPartner,
  PtiPartner,
  PviPartner,
  HVPartner,
  GrabPartner,
  MicPartner,
  ShopbackPartner,
  AhamovePartner,
  BVPartner,
  EPassPartner,
  OneLinicPartner,
  TgddPartner,
  VniPartner,
  // VacPartner,
  News1,
  News2,
  News3,
  News4,
  News5,
  BannerNha,
  BannerTaiNan,
  BannerDulich,
  BannerTrucot,
  BannerVCX,
  BannerNgheo,
  BannerBHYT,
  BannerBMCare,
  BannerMCXe,
  BannerBBXM,
  BannerTomato,
  BannerHTri,
  BannerSKV,
  BannerNhaToanDien,
  BannerVBICare,
  BannerFamilyCare,
  BannerTaiNanHoGiaDinh,
};

export default Images;
