import { Add, Remove } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { useStyles } from "../../styles/supportStyles";

import Mail from "../../assets/icons/mail-color.svg";
import Phone from "../../assets/icons/phone-color.svg";
import QrFb from "../../assets/icons/qr-fb-gsale.svg";
import QrZalo from "../../assets/icons/qr-zalo-gsale.svg";

export default function Support(props) {
  const { dispatch, state } = props;
  const { supportData } = state;
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div className="title">Hỏi đáp nhanh với GSale</div>
        {(supportData?.info || []).map((item, index) => (
          <SupportItem
            question={`${index + 1}.  ${item?.q}`}
            answer={item?.a}
            li={item?.a1 || []}
            classes={classes}
            index={index}
            expanded={supportData?.expanded || false}
            dispatch={dispatch}
          />
        ))}
      </div>
      <div className={classes.contact}>
        <div className="title">Liên hệ với GSale</div>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className="bold">Cảm ơn bạn đã chọn GSale !</div>
            <div className="bold mt-10">
              THAM GIA BẢO HIỂM TRONG 1 PHÚT Ở MỌI LÚC, MỌI NƠI
            </div>
            <div className="mt-10 fz-15">
              GSale là ứng dụng đa nền tảng của Global Care, giúp bạn tìm hiểu
              các chương trình bảo hiểm và nâng cao thu nhập thật dễ dàng thông
              qua chính sách hấp dẫn dành cho Cộng tác viên.
            </div>
            <div className="bold mt-10">Đồng hành cùng GSale ngay hôm nay!</div>
            <div className="color mt-10">Trụ sở chính</div>
            <div className="fz-15">
              1/2 đường số 33, phường An Khánh, Tp. Thủ Đức
            </div>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className="row half">
                  <img src={QrZalo} alt="map" className="mr-10" />
                  <div>
                    <span className="color"> Zalo CTV GSale </span>
                    <br /> Kết nối với GSale trên Zalo
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="row half">
                  <img src={QrFb} alt="map" className="mr-10" />
                  <div>
                    <span className="color"> GSale Facebook </span>
                    <br /> Kết nối với GSale trên Facebook
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="row half">
                  <img src={Phone} alt="map" className="mr-10" />
                  <div>
                    <span className="color"> 1900 29 29 87</span> <br /> Hotline
                    hỗ trợ <br /> Thứ 2 - thứ 6 (9h00 - 18h00)
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="row half">
                  <img src={Mail} alt="map" className="mr-10" />
                  <div>
                    <span className="color"> cskh@glogbalcare.vn</span> <br />{" "}
                    Email nhận hỗ trợ
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="wrapper">
              <div className="bg-color"></div>
              <div className="map">
                <iframe
                  title="GlobalCare"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4071.6080950392193!2d106.72636361557163!3d10.787752580569602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f62d720bb1d%3A0x8413caeb34526ab1!2sGlobal%20Care!5e0!3m2!1svi!2s!4v1691742455016!5m2!1svi!2s"
                  width="100%"
                  height="420"
                  frameborder="0"
                  allowfullscreen
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const SupportItem = ({
  question,
  answer,
  li,
  classes,
  index,
  expanded,
  dispatch,
}) => {
  return (
    <Accordion
      expanded={expanded === index + 1}
      onChange={(e, newExpanded) =>
        dispatch({ type: "setExpanded", newExpanded, panel: index + 1 })
      }
      className={classes.accordion}
      sx={{
        borderTop: "0.5px solid  #DCDCDC",
        boxShadow: 0,
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === index + 1 ? (
            <Remove sx={{ color: "#006838" }} />
          ) : (
            <Add sx={{ color: "#006838" }} />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="color">{question}</div>
      </AccordionSummary>
      <AccordionDetails>
        <div>{answer}</div>
        {(li || []).map((i) => (
          <div className="row-text li">
            <span className="add">+ </span> {i}
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
