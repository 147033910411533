import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import Title from "./Title";

import HotIcon from "../../assets/icons/hot-deal.svg";
import NewIcon from "../../assets/icons/new.svg";

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.2 },
  },
};

export default function Products(props) {
  const { list, dispatch } = props;
  const classes = useStyles();
  const myRef = useRef(null);
  const theme = useTheme();
  const [expand, setExpand] = useState(false);

  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isSm = useMediaQuery(theme.breakpoints.down("xs"));
  const max = isSm ? 4 : isLg ? 6 : 8;
  const displayedItems = expand ? list : list.slice(0, max);

  const scrollToTargetAdjusted = () => {
    const element = document.getElementById("product");
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - 140;
    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleShowAll = () => {
    expand && scrollToTargetAdjusted();
    setExpand((prevState) => !prevState);
  };

  return (
    <div id="product" ref={myRef} className={classes.wrapper}>
      <Title colorText="Những deals Bảo hiểm hấp dẫn" text="trên GSale" />
      <motion.div
        className={classes.list}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.3 }}
        variants={container}
      >
        <Grid container spacing={{ xs: 1.5, sm: 2, lg: 3 }}>
          {displayedItems.map((i, index) => (
            <Grid item key={index} xs={6} sm={4} lg={3}>
              <motion.div
                className={classes.boxItem}
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative">
                  {i.hot && (
                    <img src={HotIcon} alt="hot-deal" className="hot-deal" />
                  )}
                  {i.new && (
                    <img src={NewIcon} alt="new" className="hot-deal" />
                  )}
                  <img src={i.icon} alt={i.icon} className="logo"></img>
                  <div className="name bold">{i.name}</div>
                  <div className="describe">{i.content}</div>
                </div>
                <div>
                  <div className="commission bold">
                    Thu nhập lên đến {i.commission}
                  </div>
                  <div className="button">
                    <a
                      href="https://core.globalcare.vn/app/gsale/download"
                      target="_bank"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      Tham gia GSale
                    </a>
                  </div>
                  <div
                    className="more"
                    onClick={() => dispatch({ type: "learnMore", id: i.id })}
                  >
                    Tìm hiểu thêm
                  </div>
                </div>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
      <div className={classes.expand} onClick={handleShowAll}>
        <div className="text">{expand ? "Thu gọn" : "Xem thêm deal khác"}</div>
        {expand ? (
          <ExpandLessIcon sx={{ color: "#006838" }} />
        ) : (
          <ExpandMoreIcon sx={{ color: "#006838" }} />
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    transition: "all .2s ease-in",
    padding: "0 8%",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      marginTop: 10,
    },
  },
  list: {
    transition: "height 1s ease",
    overflow: "hidden",
    padding: "10px 0 50px 0",
  },
  boxItem: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    background: "#FFFFFF",
    border: "1px solid #DCDCDC",
    boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "19px",

    transition: "transform 0.3s ease",
    transform: "translateY(0)",
    "&:hover": {
      [theme.breakpoints.up("sm")]: {
        transform: "translateY(-5px) !important",
      },
    },

    [theme.breakpoints.down("md")]: {
      padding: "20px 10px",
      fontSize: "14px",
    },
    "& .relative": { position: "relative" },
    "& .bold": { fontWeight: 600 },
    "& .name": {
      color: "#1A1A1A",
      fontSize: 18,
      margin: "16px 0",
      [theme.breakpoints.up("sm")]: {
        margin: "10px 0",
      },
    },
    "& .describe": {
      lineHeight: 1.3,
      letterSpacing: "0.015em",
      color: "#61646D",
    },
    "& .commission": {
      letterSpacing: " 0.015em",
      color: "#006838",
      margin: "15px 0",
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    "& .button": {
      padding: "10px",
      marginBottom: "15px",
      background: "linear-gradient(180deg, #03BC71 0%, #036C4D 100%)",
      boxShadow: "0px 8px 13px rgba(0, 0, 0, 0.1)",
      borderRadius: "6px",
      cursor: "pointer",
      transition: "all 0.5s ease",
      "&:hover": {
        transform: "scale(1.08)",
      },
    },
    "& .more": {
      lineHeight: 1.2,
      textDecoration: "underline",
      color: "#00A69C",
      "&:hover": {
        fontWeight: 500,
        cursor: "pointer",
      },
    },
    "& .logo": {
      width: "70px",
      [theme.breakpoints.down("sm")]: {
        width: "60px",
      },
    },
    "& .hot-deal": {
      position: "absolute",
      top: -20,
      right: 0,
    },
  },
  expand: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
    "& .text": {
      color: "#006838",
      fontWeight: 500,
    },
  },
}));
